import { ReactComponent as LinkedInIcon } from "./icons/linkedin.svg"
import { ReactComponent as GithubIcon } from "./icons/github.svg"
import { ReactComponent as TwitterIcon } from "./icons/twitter.svg"
import { ReactComponent as UpworkIcon } from "./icons/upwork.svg"
import { ReactComponent as StackOverflowIcon } from "./icons/stackoverflow.svg"
// const works = [
//   {
//     name: "Home Property Group SA",
//     description: "HTML, CSS, SCSS",
//     imageSrc:
//       "https://images.unsplash.com/photo-1600585153490-76fb20a32601?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
//     imageAlt:
//       "Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.",
//     href: "http://homeprops.beta.entegral.biz/",
//   },
//   {
//     name: "Coach Platform",
//     description: "HTML, CSS, SCSS, JAVASCRIPT, TYPESCRIPT, VUEJS2&3",
//     imageSrc:
//       "https://tailwindui.com/img/ecommerce-images/home-page-02-edition-02.jpg",
//     imageAlt:
//       "Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.",
//     href: "https://app.carrus.io/coaches",
//   },
//   {
//     name: "Pharmaceutical website",
//     description: "HTML, CSS, JAVASCRIPT, REACTJS, ANTD",
//     imageSrc: "https://s3.amazonaws.com/prod.prescriptvm.com/media/anxiety.jpg",
//     imageAlt: "Collection of four insulated travel bottles on wooden shelf.",
//     href: "https://fortiche-front.herokuapp.com/terapiacontalita/",
//   },
// ];

function App() {
  // const [selected, setSelected] = useState({ home: "selected-circle" });
  // const [height, setHeight] = useState("h-auto");
  // const [workHeight, setWorkHeight] = useState("h-auto");

  // useEffect(()=>{
  //   const locationOrigin = window.location.origin;

  //   const sectionOneObserver = new IntersectionObserver ((entries, sectionOneObserver)=> {
  //     entries.forEach(entry=>{
  //       if(!entry?.isIntersecting){
  //         console.log("entry", entry);

  //         setHeight("h-auto")
  //       window.history.pushState({}, '',`${locationOrigin}/#work`);
  //        setSelected({ work: "selected-circle", workUrl: "text-selected-text" });
  //       }else{
  //         setHeight("h-screen")
  //         setSelected({ home: "selected-circle", homeUrl: "text-selected-text"});
  //         window.history.pushState({}, '',`${locationOrigin}/`);
  //       }
  //   })
  // })

  //   sectionOneObserver.observe(document.getElementById("content"))

  // }, [])

  // useEffect(()=>{

  //   const sectionOneObserver = new IntersectionObserver ((entries, _)=> {
  //     entries.forEach(entry=>{
  //       if(entry?.isIntersecting){
  //         console.log("entry", entry);
  //         setWorkHeight("h-screen")
  //         setHeight("h-auto")
  //       }else{
  //         setWorkHeight("h-auto")
  //       }
  //   })
  // })

  //   sectionOneObserver.observe(document.getElementById("work-content"))

  // }, [])

  return (
    // <div class="max-w-[2000] m-auto b-3">
    //   <header className="py-6 fixed top-0 left-0 right-0 bg-body z-4">
    //     <div className="container flex justify-between items-center mx-auto px-8 md:px-14 lg:px-24 w-full">
    //       <div className="text-lg font-bold">Gbemi.kadri</div>
    //       <div className="hidden md:flex space-x-12 items-center">
    //         <a
    //           href="/"
    //           className={selected?.homeUrl}
    //           onClick={() => {
    //             setSelected({ home: "selected-circle" });
    //           }}
    //         >
    //           Home
    //         </a>
    //         <a
    //           href="#work"
    //           className={selected?.workUrl}
    //           onClick={() => {
    //             setSelected({ work: "selected-circle" });
    //           }}
    //         >
    //           My work
    //         </a>
    //       </div>
    //       <div className="md:hidden">
    //         <svg
    //           width="26"
    //           height="18"
    //           viewBox="0 0 26 18"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path
    //             d="M13 17.5H0.25V14.6667H13V17.5ZM25.75 10.4167H0.25V7.58333H25.75V10.4167ZM25.75 3.33333H13V0.5H25.75V3.33333Z"
    //             fill="white"
    //           />
    //         </svg>
    //       </div>
    //     </div>
    //   </header>

    //   <div id="content" className={`container mt-16 flex justify-between items-center mx-auto px-8 md:px-14 lg:px-24 w-full ${height}`}>
    //     <div className="flex flex-wrap md:flex-nowrap">
    //       <nav className="lg:mr-24 lg:w-4 fixed left-percentage hidden xl:block">
    //         <div className="absolute left-50 transform-translate-x-1/2 space-y-6 mt-36">
    //           <a
    //             href="/"
    //             id="home"
    //             onClick={() => {
    //               setSelected({ home: "selected-circle" });
    //             }}
    //             className={`nav-dot ${selected?.home} block w-7 h-7 rounded-full border-4 border-nav bg-body`}
    //           >
    //             <span className="bg-black px-2 py-1 rounded-md ml-10 opacity-0">
    //               Home
    //             </span>
    //           </a>
    //           <a
    //             href="#work"
    //             id="work-dot"
    //             onClick={() => {
    //               setSelected({ work: "selected-circle" });
    //             }}
    //             className={`nav-dot ${selected?.work} block w-7 h-7 rounded-full border-4 border-nav bg-body`}
    //           >
    //             <span className="bg-black px-2 py-1 rounded-md ml-10 opacity-0">
    //               Work
    //             </span>
    //           </a>
    //         </div>
    //       </nav>

    //       <div className="flex flex-wrap">
    //         <div className="flex flex-wrap lg:ml-20 justify-center md:justify-start max-w-xl mt-0 md:my-36 flex-1">
    //           <h1 className="font-bold text-5xl md:text-6xl lg:text-7xl text-center md:text-left">
    //             Building
    //             <br /> beautiful web experiences.
    //           </h1>
    //           <div className="w-full justify-center md:justify-start">
    //             <button className="px-8 py-4 bg-theme text-white font-bold mt-12 flex items-center space-x-3">
    //               <div>
    //                 <svg
    //                   width="22"
    //                   height="22"
    //                   viewBox="0 0 22 22"
    //                   fill="none"
    //                   xmlns="http://www.w3.org/2000/svg"
    //                 >
    //                   <path
    //                     d="M14.4375 11C14.4375 11.9117 14.0753 12.786 13.4307 13.4307C12.786 14.0753 11.9117 14.4375 11 14.4375C10.0883 14.4375 9.21398 14.0753 8.56932 13.4307C7.92466 12.786 7.5625 11.9117 7.5625 11C7.5625 10.0883 7.92466 9.21398 8.56932 8.56932C9.21398 7.92466 10.0883 7.5625 11 7.5625C11.9117 7.5625 12.786 7.92466 13.4307 8.56932C14.0753 9.21398 14.4375 10.0883 14.4375 11Z"
    //                     fill="white"
    //                   />
    //                   <path
    //                     d="M0 11C0 11 4.125 3.4375 11 3.4375C17.875 3.4375 22 11 22 11C22 11 17.875 18.5625 11 18.5625C4.125 18.5625 0 11 0 11ZM11 15.8125C12.2764 15.8125 13.5004 15.3055 14.403 14.403C15.3055 13.5004 15.8125 12.2764 15.8125 11C15.8125 9.72365 15.3055 8.49957 14.403 7.59705C13.5004 6.69453 12.2764 6.1875 11 6.1875C9.72365 6.1875 8.49957 6.69453 7.59705 7.59705C6.69453 8.49957 6.1875 9.72365 6.1875 11C6.1875 12.2764 6.69453 13.5004 7.59705 14.403C8.49957 15.3055 9.72365 15.8125 11 15.8125Z"
    //                     fill="white"
    //                   />
    //                 </svg>
    //               </div>
    //               <a
    //                 href="#work"
    //                 onClick={() => {
    //                   setSelected({ work: "selected-circle" });
    //                 }}
    //               >
    //                 <span>View my work</span>
    //               </a>
    //             </button>
    //           </div>
    //         </div>
    //         <img
    //           src={process.env.PUBLIC_URL + "/img/me.jpg"}
    //           alt="Me"
    //           style={{ width: "525px", height: "700px" }}
    //           className="w-3/4 mt-12 -mt-6 md:mt-0 right-0 -z-1 grayscale flex-1"
    //         />
    //       </div>
    //     </div>
    //   </div>

    //   <div id="work-content" className={`container mt-64 mb-24 flex justify-between items-center mx-auto px-8 md:px-14 lg:px-24 w-full ${workHeight}`}>
    //     <section className="w-full">
    //       <h2 id="work" className="secondary-title">
    //         My work
    //       </h2>
    //       <p className="section-paragraph">
    //         I’ve had the pleasure of working with multiple companies, designing
    //         and implementing mostly frontend web applications.
    //       </p>

    //       <div>
    //         <div className="max-w-7xl mx-auto">
    //           <div className="max-w-2xl mx-auto lg:max-w-none">
    //             <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
    //               {works.map((work) => (
    //                 <div key={work.name} className="group relative">
    //                   <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
    //                     <img
    //                       src={work.imageSrc}
    //                       alt={work.imageAlt}
    //                       className="w-full h-full object-center object-cover"
    //                     />
    //                   </div>
    //                   <h3 className="mt-6 text-sm text-white-500">
    //                     <a href={work.href} target="_blank" rel="noreferrer">
    //                       <span className="absolute inset-0" />
    //                       {work.name}
    //                     </a>
    //                   </h3>
    //                   <p className="text-base font-semibold text-white-900">
    //                     {work.description}
    //                   </p>
    //                 </div>
    //               ))}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   </div>
    // </div>
    // Upwork and Stack overflow
    <div className="grid h-screen content-center text-center w-screen px-16">
      <div className="max-w-xl m-auto py-2">
        <img
          src={process.env.PUBLIC_URL + "/img/me.jpeg"}
          alt="Me"
          className="drop-shadow-lg object-cover inline-block h-40 w-40 rounded-full ring-2 ring-white"
        />
        <p className="py-4 text-2xl tracking-widest uppercase bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-blue-100">
          Software Engineer | Code lover
        </p>
        <p className="pb-1 text-sm max-w-md m-auto">
          I am Gbemi Kadri a software engineer based in Tallinn, Estonia. I
          specialize in website development. You can also call me a JavaScript
          developer.
        </p>
        <a
          href="https://cvdesignr.com/p/66b51e2be0e11"
          class="underline decoration-1 pt-4 tracking-wide text-sm text-blue-500 font-semibold"
        >
          My CV
        </a>
      </div>
      <div className="grid grid-cols-5 gap-4 pt-4 max-w-screen-md text-center m-auto">
        <a href="https://www.linkedin.com/in/kadri-oluwagbemi/">
          <LinkedInIcon
            width={20}
            fill="#0A66C2"
            className="cursor-pointer hover:fill-[#1483f3]"
          />
        </a>
        <a href="https://github.com/KadriGbemi">
          <GithubIcon
            width={20}
            fill="#181717"
            className="cursor-pointer hover:fill-[#7c7777]"
          />
        </a>
        <a href="https://twitter.com/GbemiKadri">
          <TwitterIcon
            width={20}
            fill="#1DA1F2"
            className="cursor-pointer hover:fill-[#42b0f4]"
          />
        </a>
        <a href="https://www.upwork.com/freelancers/~01a2e525a0adb071d5">
          <UpworkIcon
            width={20}
            fill="#6FDA44"
            className="cursor-pointer hover:fill-[#204b0f]"
          />
        </a>
        <a href="https://stackoverflow.com/users/8608592/oluwagbemi-kadri">
          <StackOverflowIcon
            width={20}
            fill="#F58025"
            className="cursor-pointer hover:fill-[#d5630a]"
          />
        </a>
      </div>
    </div>
  )
}

export default App
